import React from 'react';

const Alert = () => (
	<React.Fragment>
		<div className='errorContainer'>
			<p>Enter A User Name</p>
		</div>
	</React.Fragment>
);

export { Alert };
